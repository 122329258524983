export default {
  template: `
        <div style="overflow-y: scroll;" id="foo">
        <div class="customHeaderLabel"> 
        <span
        v-if="isFunctionColumn"
        @click="showFunctionPoupUp"
        style="display: inline-block; vertical-align: middle; line-height: normal;"
        class="material-icons cursor-pointer"
      >
      functions
      </span>
        <span @click="toggleEdit" v-if="!showEdit"> {{params.displayName || 'Unnamed'}} </span>
      <t-input @focus="$event.target.select()" v-if="showEdit" style="display: inline-block; background: transparent; color: white;
       border: none; height: 20px; max-width: 50%; min-width: 50px; font-weight: 600; font-size: 12px;" v-model="columnName"> </t-input> 
       <span
       @click="showEdit= false; columnName = params.displayName;"
       v-if="showEdit"
      style="display: inline-block; color: rgb(238, 32, 77);  vertical-align: middle; line-height: normal;"
      class="material-icons cursor-pointer"
    >
      close
    </span>
    <span
      @click="showEdit= false; showMenu()"
       v-if="showEdit"
      style="display: inline-block; color: rgb(66,219,192);  vertical-align: middle; line-height: normal;"
      class="material-icons cursor-pointer"
    >
      check
    </span>
    <span
    @click="showEdit= false; removeColumn()"
     v-if="showEdit"
    style="display: inline-block; color: rgb(238, 32, 77);  vertical-align: middle; line-height: normal;"
    class="material-icons cursor-pointer"
  >
    delete
  </span>
      </div>
        <div v-if="params.enableSorting" @click="onSortRequested('asc', $event)" :class="ascSort" class="customSortDownLabel">
          <i class="fa fa-long-arrow-alt-down"></i></div>
        <div v-if="params.enableSorting" @click="onSortRequested('desc', $event)" :class="descSort" class="customSortUpLabel">
          <i class="fa fa-long-arrow-alt-up"></i></div>
        <div v-if="params.enableSorting" @click="onSortRequested('', $event)" :class="noSort" class="customSortRemoveLabel">
          <i class="fa fa-times"></i>
        </div>


       
        </div>
      `,
  data() {
    return {
      ascSort: null,
      descSort: null,
      noSort: null,
      columnName: 'Column',
      menuRight: 0,
      isFunctionColumn: false,
      columnType: '',
      menuTop: '315px',
      showEdit: false,
      showColumnMenu: false,
    };
  },
  beforeMount() {
    // console.log(this.params);
    this.columnName = this.params.displayName;
    if(this.params.column && this.params.column.colId.includes('Function')){
      this.isFunctionColumn = true;
    }
    // this.params.displayName = this.params.displayName === '' ? 'Unnamed' : this.params.displayName;
  },
  mounted() {
    this.params.column.addEventListener('sortChanged', this.onSortChanged);
    this.onSortChanged();
  },

  methods: {
    onMenuClicked() {
      this.params.showColumnMenu(this.$refs.menuButton);
    },

    toggleEdit() {
      if (this.params.displayName !== 'Tags') {
        this.showEdit = true;
      }
    },

    removeColumn() {
      // console.log(this.params.column.colId);
      const makeCol = this.params.columnApi.getColumn(this.params.column.colId);
      this.params.context.componentParent.removeColumn(this.columnName,makeCol.colDef.colId);
    },

    showMenu() {
      // console.log(this.params.column.colId);
      const makeCol = this.params.columnApi.getColumn(this.params.column.colId);
      this.params.context.componentParent.updateColumnName(makeCol.colDef.headerName, this.columnName, makeCol.colDef.colId);
      // console.log(makeCol);
      makeCol.colDef.headerName = this.columnName;
      this.params.api.refreshHeader();
    },

    onSortChanged() {
      // eslint-disable-next-line no-multi-assign
      this.ascSort = this.descSort = this.noSort = 'inactive';
      if (this.params.column.isSortAscending()) {
        this.ascSort = 'active';
      } else if (this.params.column.isSortDescending()) {
        this.descSort = 'active';
      } else {
        this.noSort = 'active';
      }
    },


    showFunctionPoupUp(){
      this.params.context.componentParent.showFunctionPoupUp(this.params.column);
    },

    add(text) {
      this.params.context.componentParent.methodFromParent(text, text);
      this.columnName = '';
      this.columnType = '';
    },

    onSortRequested(order, event) {
      this.params.setSort(order, event.shiftKey);
    },
  },
};
