import Vue from 'vue';

export default Vue.extend({

  data() {
    return {
      showBtn: false,
      width: window.innerWidth,
    };
  },
  created() {
    if (this.width < 700) {
      this.showBtn = true;
    }
  },
  methods: {
    deleteRow() {
      const objectID = this.params.data.Well;
      const gridApi = this.params.api;
      // console.log(gridApi.getRowNode(objectID))
      this.params.context.componentParent.removeRow(objectID);

    //   this.params.api.updateRowData({ remove: [rowNode ] });
    },

  },
  template: `
      <div id="testing" @mouseover="showBtn = true" @mouseleave="showBtn = false"   @click="deleteRow()">

      <p
      v-if="!showBtn"
      @mouseover="showBtn = true" @mouseleave="showBtn = false"
      style="width: 100%; color: transparent;"
      class="material-icons cursor-pointer"
    >
      delete
    </p>
      <span
      v-if="showBtn"
      @mouseover="showBtn = true;" @mouseleave="showBtn = false"
      @click="deleteRow()"
      style="display: inline-block;  vertical-align: middle; line-height: normal;"
      class="material-icons cursor-pointer"
    >
      delete
    </span>
    <span
    @mouseover="showBtn = true" @mouseleave="showBtn = false" v-if="showBtn"
    @click="deleteRow()"
    style="display: inline-block; vertical-align: middle; line-height: normal;"
    class=" cursor-pointer"
  >
    <span>Delete Well</span>
  </span>
    </div>
    `,
});
