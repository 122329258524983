<template>
  <div>
    <textarea
      ref="textSuggestionRef"
      v-model="inputVal"
      :name="name"
      placeholder="([Column A]* [Column B]) / 20"
      style="overflow: hidden; width:100%; background: transparent; border: rgba(255,255,255,0.4) solid 1px; border-radius: 5px; color: white; padding: 10px; padding-top: 8px;"
      @blur="textSuggestionControl"
      @keyup="getReferralSuggestion(inputVal)"
    />
    <div
      v-show="textSuggestionState"
      id="content"
      ref="content"
    >
      <div>
        <div
          id="scrollContent1"
          :style="{'width' : 500 +'px'}"
          class="textArea-suggestion"
        >
          <ul
            v-for="refSearch in referralSearch"
            id="ulScrollContent"
            style="list-style:none;margin : 0;padding: 0;"
            class="scrollContent"
          >
            <li
              style="white-space: nowrap;overflow: hidden;text-overflow: ellipsis;"
              :style="{'width' : textSuggestionWidth -40 +'px'}"
              :class="inputValIdTemp == refSearch.id ? 'selectedWithArrow' : ''"
              @click.self="setReferralTest(refSearch)"
            >
              {{ refSearch.text }}
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {

  props: ['options', 'value', 'name'],
  data() {
    return {
      textSuggestionState: false,
      scrollVisible: false,
      textSuggestionWidth: 0,
      referralSearch: [],
      inputVal: this.value,
      selectedIndex: -1,
      referralSearchSelectedId: 0,
      inputValTemp: '',
      inputValIdTemp: 0,
    };
  },
  watch: {
    inputVal(newValue) {
      this.$emit('input', newValue);
    },
  },
  created() {
  },
  updated() {
  },
  methods: {
    getReferralSuggestion(query) {
      switch (event.keyCode) {
        case 40:
          this.inputValTemp = '';
          if (this.selectedIndex < this.referralSearch.length - 1) this.selectedIndex += 1;
          else this.selectedIndex = 0;
          // console.log(this.selectedIndex + ' : ' + this.referralSearch.length);
          this.inputValTemp = this.referralSearch[this.selectedIndex].text;
          this.inputValIdTemp = this.referralSearch[this.selectedIndex].id;
          this.setScroll();
          // console.log(JSON.stringify( this.referralSearch[this.selectedIndex]));
          break;
        case 38:
          this.inputValTemp = '';
          if (this.selectedIndex < this.referralSearch.length) {
            if (this.selectedIndex > 0) this.selectedIndex -= 1;
            else this.selectedIndex = 0;
          }
          // console.log(this.selectedIndex);
          this.inputValTemp = this.referralSearch[this.selectedIndex].text;
          this.inputValIdTemp = this.referralSearch[this.selectedIndex].id;
          this.setScroll();
          // console.log(JSON.stringify( this.referralSearch[this.selectedIndex]));
          break;
        case 13:
          event.preventDefault();
          if (this.selectedIndex != -1) {
            // let words = this.inputVal.replace(/(\r\n|\n|\r)/gm, '__br__ ').split(' ');
            // words[words.length - 1] = this.inputValTemp
            this.inputVal = `${this.inputVal} ${this.inputValTemp.trim()}`;
          }
          this.selectedIndex = 0;
          break;
        default:
          document.getElementById('scrollContent1').scrollTo({ top: 0, behavior: 'smooth' });
          this.selectedIndex = -1;
          this.textSuggestionWidth = this.$refs.textSuggestionRef.clientWidth;
          var state = false;
          if (query == '') {
            this.referralSearch = [];
            this.scrollVisible = false;
          } else {
            this.referralSearch = [];
            const currentWord = query.replace(/(\r\n|\n|\r)/gm, '__br__ ').split(' ');
            // console.log(currentWord)
            // console.log(currentWord);
            const result = [currentWord[currentWord.length - 1]];
            // console.log(result);
            this.options.forEach((item) => {
              if ((item.text.includes(result))) {
                state = true;
                if (state) {
                  const rTemp = {};
                  rTemp.id = item.id;
                  rTemp.text = item.text;
                  this.referralSearch.push(rTemp);
                  this.scrollVisible = true;
                } else this.selectedIndex = -1;
              } else {
                this.scrollVisible = false;
              }
            });
          }
          if (this.inputVal == '' || this.inputVal == null || this.referralSearch.length == 0) this.textSuggestionState = false;
          else this.textSuggestionState = true;
          break;
      }
    },
    setScroll() {
      const selected = this.selectedIndex;
      const elHeight = document.getElementById('ulScrollContent').clientHeight;
      const scrollTop = document.getElementById('scrollContent1').scrollTo({ top: 0, behavior: 'smooth' });
      // document.getElementById('scrollContent1').scrollTop += 25;
      const viewport = scrollTop + document.getElementById('scrollContent1').clientHeight;
      const elOffset = elHeight * selected;
      if (elOffset < scrollTop || (elOffset + elHeight) > viewport) document.getElementById('scrollContent1').scrollTo({ top: elOffset, behavior: 'smooth' });
    },
    setReferralTest(input) {
      const words = this.inputVal.replace(/(\r\n|\n|\r)/gm, '__br__ ').split(' ');
      words[words.length - 1] = input.text;
      this.inputVal = words.join(' ');
      this.textSuggestionState = false;
    },
    textSuggestionControl() {
      const _this = this;
      setTimeout(() => {
        _this.textSuggestionState = false;
      }, 300);
    },
  },
};
</script>

<style scoped>
.form-element-margin-btm {
    margin-bottom: 0px;
}
.margin-top-grid{
    margin-top: 8px;
}
.textArea-suggestion{
    position: absolute;
    z-index: 9999;
   background-color: #313d4c;
    box-shadow: 0px 0px 5px #1E90FF;
    transition: all .15s ease;
    -webkit-transform: translateY(-2px);
    max-height: 150px;
    min-height: 38px;
    overflow-y: auto;
}

.textArea-suggestion ul li{
    padding: 8px;
    font-weight: normal;
    background:  #313d4b;
    color : white;
}

.textArea-suggestion ul li:hover{
    background-color: #28313c;
    cursor: pointer;
}

.scrollContent{

}
.selectedWithArrow{
    background-color: #F5F5F5;
}

textarea:focus {
    background-color: #fefefe;
    border: 1px solid #8a8a8a;
    box-shadow: 0 0 5px #1E90FF;
    outline: medium none;
    transition: box-shadow 0.5s ease 0s, border-color 0.25s ease-in-out 0s;

}
</style>
