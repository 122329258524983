<template>
  <div>
    <span v-if="selectedTags === []">-</span>
    <multiselect
      :id="tagID"
      :ref="tagID"
      v-model="selectedTags"
      deselect-label=""
      class="multiselect-custom col-span-4 ag-input"
      style=" max-width: 635px; overflow-x: scroll; overflow-y: hidden;"
      :multiple="true"
      :hide-selected="true"
      :max-height="150"
      :taggable="true"
      placeholder=""
      open-direction="above"
      tag-position="bottom"
      :options="availableTags"
      :searchable="true"
      :allow-empty="true"
      @open="updateDropdownSize"
      @select="selectTag"
      @remove="removeTag"
      @tag="addTag"
    />
  </div>
</template>
<script>
import Vue from 'vue';
import Multiselect from 'vue-multiselect';
import accountModule from '@/store/modules/accountModule';
import scheduleModule from '@/store/modules/scheduleModule';
import workflowModule from '@/store/modules/workflowModule';

export default {
  components: {
    Multiselect,
  },

  data() {
    return {
      selectedTags: [],
      wellTags: [],
      cancelBeforeStart: true,
      availableTags: [],
      highlightAllOnFocus: true,
      value: [],
      tagID: '',
    };
  },
  async beforeMount() {
    const objectID = this.params.data.Well;

    this.tagID = `${objectID}Tag`;

    const wellTags = await scheduleModule.getTagsForWell({ node_id: objectID });
    if (wellTags.length) {
      this.selectedTags = this.selectedTags.concat(wellTags.map((t) => JSON.parse(t).toLowerCase()));
    }

    // await workflowModule.getUserAssignedToWell({nodeId: objectID})
    // await workflowModule.getLatestDeferrmentValueForWell({nodeIdList: objectID})
  },

  async created() {
    const groups = (scheduleModule.activeTableGroups);
    const payload = [];
    let tags = [];
    const allWellTags = scheduleModule.availableTags;
    if (allWellTags.length) {
      tags = (allWellTags);
      tags = tags.map((t) => t.toLowerCase());
    }

    this.availableTags = [...new Set(tags)].sort();
  },

  isPopup() {
    return true;
  },

  methods: {

    activeTableGroups() {
      return scheduleModule.activeTableGroups;
    },

    updateDropdownSize() {
      const element = this.$refs[this.tagID].$el;
      if (element) {
        const dropdown = element.querySelector('.multiselect__content-wrapper');
        const cor = element.getBoundingClientRect();
        const top = cor.top - 300;
        if (top > 50) {
          dropdown.style.top = `${top}px`;
        }
      }
    },
    sleep(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    },
    async removeTag(removeT) {
      await this.sleep(200);
      const fieldName = this.params.colDef.colId;
      const objectID = this.params.data.Well;
      const gridApi = this.params.api;
      const rowNode = gridApi.getRowNode(objectID);
      const tags = this.selectedTags;
      const index = tags.indexOf(removeT);
      if (index > -1 && tags.includes(removeT)) {
        tags.splice(index, 1);
      }
      this.selectedTags = this.selectedTags.map((t) => t.toLowerCase());
      // console.log(this.selectedTags);
      await this.params.context.componentParent.updateDataFromCell(rowNode.childIndex, fieldName, this.selectedTags);
      await scheduleModule.addTagsToWell({ node_id: objectID, tags });
    },
    async addTag(newTag) {
      await this.sleep(200);
      this.selectedTags.push(newTag);
      const fieldName = this.params.colDef.colId;
      const objectID = this.params.data.Well;
      this.value = this.selectedTags;
      this.selectedTags = this.selectedTags.map((t) => t.toLowerCase());
      const gridApi = this.params.api;
      const rowNode = gridApi.getRowNode(objectID);
      await scheduleModule.addTagsToWell({ node_id: objectID, tags: this.selectedTags });
      this.params.context.componentParent.updateDataFromCell(rowNode.childIndex, fieldName, (this.selectedTags));
    },

    async selectTag() {
      await this.sleep(200);

      const fieldName = this.params.colDef.colId;
      const objectID = this.params.data.Well;
      this.value = this.selectedTags;
      const gridApi = this.params.api;
      const rowNode = gridApi.getRowNode(objectID);
      this.selectedTags = this.selectedTags.map((t) => t.toLowerCase());
      await scheduleModule.addTagsToWell({ node_id: objectID, tags: this.selectedTags });
      this.params.context.componentParent.updateDataFromCell(rowNode.childIndex, fieldName, (this.selectedTags));
    },
  },
};
</script>



<style lang="postcss" scoped>
.multiselect-custom >>> .multiselect__tags {
  border-color: transparent;
  max-height: 35px;
  padding: 4px;
  @apply bg-blue028;
}

.multiselect-custom >>> .multiselect__select {
  display: none;
}

.multiselect-custom >>> input {
  color: white;
  @apply bg-blue028;
}

.multiselect-custom >>> .multiselect__option--highlight:after{
  display: none;
  @apply bg-green011;
}

.multiselect-custom >>> .multiselect__content-wrapper {
  position: fixed;
  z-index: 99999 !important;
  height: 150px !important;
  top: 50px;
  width: 300px;
}

.multiselect-custom >>> .multiselect--active {
  z-index: 10;
}
</style>
